import { type ClerkAPIResponse, type ClerkError } from ".";

export function isClerkAPIResponse(err: unknown): err is ClerkAPIResponse {
  return (
    typeof err === "object" &&
    err !== null &&
    "status" in err &&
    "clerkError" in err &&
    "errors" in err
  );
}

export function isClerkError(err: unknown): err is ClerkError {
  return (
    typeof err === "object" &&
    err !== null &&
    "code" in err &&
    "message" in err &&
    "longMessage" in err &&
    "meta" in err
  );
}
