import * as z from 'zod';

export const publicUserAuthSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  company: z.string().min(1),
  email: z.string().email(),
  password: z.string().min(8, 'Min length is 8'),
});
export type PublicUserAuthFormData = z.infer<typeof publicUserAuthSchema>;

export const userAuthSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  password: z.string().min(8),
});
export type UserAuthFormData = z.infer<typeof userAuthSchema>;

export const codeValidationSchema = z.object({
  code: z.string().min(1),
});
export type CodeValidationFormData = z.infer<typeof codeValidationSchema>;

export const userLoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});
export type UserLoginFormData = z.infer<typeof userLoginSchema>;

export const resetPasswordSchema = z.object({
  email: z.string().email(),
});
export type ResetPasswordFormData = z.infer<typeof resetPasswordSchema>;

export const resetPasswordCodeValidationSchema = z.object({
  code: z.string().min(1),
  password: z.string().min(8),
});
export type ResetPasswordCodeValidationFormData = z.infer<
  typeof resetPasswordCodeValidationSchema
>;
